import React from 'react'
import Layout from '../../components/layout'
import { Box, Typography } from '@material-ui/core'
import illustration from './illustration_thank_you.png'

const ContactThanks = () => (
  <Layout pageTitle="Thanks For Reaching Out" pageDescription="">
    <Box display="flex" justifyContent="center" minHeight="100vh">
      <Box display={'block'}>
        <Typography variant={'h6'} style={{ marginBottom: '40px' }}>
          Thanks For Reaching Out
        </Typography>
        <Typography variant={'body1'} style={{ marginBottom: '80px' }}>
          We'll get in touch with you soon
        </Typography>
        <Typography variant={'body1'} align={'left'}>
          - Team LightTag
        </Typography>
        <img src={illustration} style={{ height: '60px' }} />
      </Box>
    </Box>
  </Layout>
)

export default ContactThanks
